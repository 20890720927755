import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CampainPage = lazy(() => import('../modules/campaigns/CampaignPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/app/campaigns' component={CampainPage} />
        <Route path='/app/influencers' component={CampainPage} />
        {/* <Route path='/crafted/pages/profile' component={ProfilePage} /> */}
        {/* <Route path='/crafted/pages/wizards' component={WizardsPage} /> */}
        {/* <Route path='/crafted/widgets' component={WidgetsPage} /> */}
        {/* <Route path='/crafted/account' component={AccountPage} /> */}
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
